import React from 'react';
import NextApp from 'next/app';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@utils/theme';
import '@static/css/index.css';
import Layout from '@components/Layout';
import { getInitStore } from '@stores/index';
import { PageTransition } from 'next-page-transitions';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import 'mobx-react-lite/batchingForReactDom';
import _get from 'lodash/get';
import dayjs from 'dayjs';
require('dayjs/locale/zh');
import cookie from 'js-cookie';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TIMEOUT = 400;

class App extends NextApp {
  constructor(props) {
    super(props);
    this.mobxStore = getInitStore({});
  }

  componentDidMount() {
    // init global config
    this.mobxStore.globalStore.init();
    this.mobxStore.accountStore.init();
    this.mobxStore.orderStore.init();
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    const lang = cookie.get('language');
    if (lang) {
      const { router } = this.props;
      if (_get(router, 'locale') !== lang) {
        if (lang === 'en') {
          window.location.href = `${window.location.origin}${_get(router, 'asPath')}`;
        } else {
          window.location.href = `${window.location.origin}/${lang}${_get(router, 'asPath')}`;
        }
      }
      if (lang === 'cn') {
        dayjs.locale('zh');
      }
    }
  }

  render() {
    const { Component, pageProps, router } = this.props;

    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
          <meta name="facebook-domain-verification" content="dud27j0a8kmja4r66bx7pwwdiqj9gm" />
          <meta
            name="description"
            content="SellOnLive is a live video commerce enabler for brands looking to expand their e-commerce business. We integrate our influencer, content and e-commerce know-hows, to provide end-to-end live video commerce solutions."
          />
          <meta
            name="keywords"
            content="FB直播小幫手,直播+1, SellOnLive,sell on live,直播, 直播小帮手, malaysia,live commerce,influencer,kol,shopping,live shopping"
          />
          <meta name="format-detection" content="telephone=no" />
          <meta name="theme-color" content={theme.palette.primary.main} />
          <link rel="icon" type="image/x-icon" href="/static/images/favicon.ico" />
          <link rel="apple-touch-icon" href="/static/images/apple-touch-icon.png" />
          <link rel="shortcut icon" href="/static/images/favicon-32x32.png" />
          <link rel="preconnect" href="https://sellonlive.tech" />
          <link rel="preconnect" href="http://pagead2.googlesyndication.com" />
          <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
          {process.env.NEXT_PUBLIC_GA_ID && (
            <>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
                  `,
                }}
              />
            </>
          )}
          {process.env.NEXT_PUBLIC_GOOGLE_TAG_ID && process.env.NEXT_PUBLIC_GA_ID && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_ID}');`,
                }}
              />
            </>
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
          {
            "@context": "https://schema.org/",
            "@type": "LocalBusiness",
            "name": "SellOnLive Tech",
            "address": "16-2D, JALAN BS 1/3, TAMAN BUKIT SERDANG, PUSAT PERDAGANGAN OLIVE HILL, SEKYSEN 1 43300, SERI KEMBANGAN, SELANGOR",
            "image": "https://sellonlive.tech/static/images/logo.svg",
            "telephone": "016-2433083",
            "url": "https://sellonlive.tech/"
          }`,
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.fbAsyncInit = function() {
                  FB.init({
                    appId            : ${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID},
                    autoLogAppEvents : true,
                    xfbml            : true,
                    status           : true,
                    cookie           : true,
                    version          : "${process.env.NEXT_PUBLIC_FACEBOOK_VERSION}"
                  });
                };`,
            }}
          ></script>
          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/en_US/sdk.js"
          ></script>
        </Head>
        <ThemeProvider theme={theme}>
          {process.env.NEXT_PUBLIC_GOOGLE_TAG_ID && (
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            </noscript>
          )}
          <CssBaseline />
          <Provider store={this.mobxStore}>
            <Layout>
              <PageTransition timeout={TIMEOUT} classNames="page-transition">
                <Component {...pageProps} key={router.route} />
              </PageTransition>
            </Layout>
          </Provider>
        </ThemeProvider>

        <style jsx global>{`
          .page-transition-enter {
            opacity: 0;
            transform: translate3d(0, 20px, 0);
          }
          .page-transition-enter-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
          }
          .page-transition-exit {
            opacity: 1;
          }
          .page-transition-exit-active {
            opacity: 0;
            transition: opacity ${TIMEOUT}ms;
          }
        `}</style>
      </>
    );
  }
}

export default appWithTranslation(App);
