import { isServer } from '@constants';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import cookie from 'js-cookie';

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
/**
 * create init store factory
 *
 * @param {Class} StoreClass mobx store class
 * @returns {Function} function to get store
 */
export function getInitStoreFactory(StoreClass) {
  let store = null;
  return initialState => {
    if (isServer) {
      return new StoreClass(initialState);
    } else {
      if (store === null) {
        store = new StoreClass(initialState);
      }
      return store;
    }
  };
}

export function isFullfillFreeShipping({ order, selectedState }) {
  const selectedStateKey = selectedState ? selectedState.toLowerCase().replace(' ', '_') : '';
  const subtotal = _get(order, 'subtotal');
  const hold_shipping_orders_subtotal = _get(order, 'hold_shipping_orders_subtotal');
  const enable_free_shipping = _get(order, 'seller.enable_free_shipping', false);
  const free_shipping_amount = _get(
    order,
    `seller.free_shipping_amount_in_${selectedStateKey}`,
    false
  );
  const is_free_shipping_in = _get(order, `seller.is_free_shipping_in_${selectedStateKey}`, false);
  if (enable_free_shipping && is_free_shipping_in) {
    if (hold_shipping_orders_subtotal >= free_shipping_amount || subtotal >= free_shipping_amount) {
      const has_free_shipping_period = _get(order, 'seller.has_free_shipping_period', false);
      if (has_free_shipping_period) {
        const free_shipping_ended_at = _get(order, 'seller.free_shipping_ended_at');
        const free_shipping_started_at = _get(order, 'seller.free_shipping_started_at');
        const isBetween = dayjs().isBetween(free_shipping_started_at, free_shipping_ended_at);
        if (isBetween) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function getDeliveryShippingFee({ shippingZone, order, selectedState = '' }) {
  let shippingFee = 0;
  const west_malaysia_shipping_fee_format = _get(
    order,
    'seller.west_malaysia_shipping_fee_format',
    'common_shipping_fee'
  );
  const east_malaysia_shipping_fee_format = _get(
    order,
    'seller.east_malaysia_shipping_fee_format',
    'common_shipping_fee'
  );
  const selectedStateKey = selectedState ? selectedState.toLowerCase().replace(' ', '_') : '';

  if (shippingZone === 'EM') {
    if (east_malaysia_shipping_fee_format === 'common_shipping_fee') {
      shippingFee = _get(order, 'seller.east_malaysia_shipping_fee', 0);
    } else {
      shippingFee = selectedStateKey
        ? _get(order, `seller.shipping_fee_in_${selectedStateKey}`, 0)
        : 0;
    }
  } else {
    if (west_malaysia_shipping_fee_format === 'common_shipping_fee') {
      shippingFee = _get(order, 'seller.west_malaysia_shipping_fee', 0);
    } else {
      shippingFee = selectedStateKey
        ? _get(order, `seller.shipping_fee_in_${selectedStateKey}`, 0)
        : 0;
    }
  }
  if (isFullfillFreeShipping({ order, selectedState })) {
    return 0;
  }
  return shippingFee;
}

export const getResponseErrors = (response, fallbackMessage) => {
  let responseMessage = _get(response, 'message', fallbackMessage);
  const errors = _get(response, 'errors', {});
  let error_messages = '';
  for (let key in errors) {
    if (errors[key] && errors[key].length > 0) {
      errors[key].map(message => {
        error_messages += message;
      });
    }
  }
  responseMessage += error_messages;
  return responseMessage;
};

export const getStoreDomain = subdomain => {
  if ('http://localhost:3000' === process.env.NEXT_PUBLIC_DOMAIN_URL) {
    return 'http://localhost:3000';
  } else if ('https://dev.sellonlive.tech' === process.env.NEXT_PUBLIC_DOMAIN_URL) {
    return `https://${subdomain}.client.sellonlive.tech`;
  } else {
    return `https://${subdomain}.sellonlive.tech`;
  }
};

export const hasActiveOfflinePaymentBanks = (paymentBanks = []) => {
  if (!paymentBanks || paymentBanks.length === 0) return false;
  let hasValidOption = false;
  paymentBanks.map(bank => {
    if (
      _get(bank, 'is_active', false) &&
      _get(bank, 'payment_bank') &&
      _get(bank, 'payment_bank_account_name') &&
      _get(bank, 'payment_bank_account_no')
    ) {
      hasValidOption = true;
    }
  });
  return hasValidOption;
};

export const getFinalLocale = ({ localeFromServer, localeFromCookies, storeLocale }) => {
  let finalLocale = localeFromServer;
  let needRedirect = false;
  if (localeFromCookies) {
    if (localeFromCookies !== localeFromServer) {
      finalLocale = localeFromCookies;
      needRedirect = true;
    }
  } else {
    if (storeLocale !== localeFromServer) {
      finalLocale = storeLocale;
      needRedirect = true;
    }
    cookie.set('language', storeLocale, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
  }
  return { finalLocale, needRedirect };
};
